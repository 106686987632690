/* Components */
.card {
    transition: all 0.25s;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);

    overflow: auto;
}

.icon {
    fill: var(--black);
    transition: all 0.15s;
}

.icon:hover {
    fill: var(--red);
    color: var(--red);
    transform: scale(1.2);
}

.slick-slide div {
    outline: 0;
}

::-webkit-scrollbar-track {
    background-color: var(--lightGrey);
}

::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: var(--red);
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--red);
}
