body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    letter-spacing: 0.02rem;
    -webkit-overflow-scrolling: touch;

    cursor: default;
}

/* Positioning */
.fixed {
    position: fixed;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
